.total-title {
  background: linear-gradient(142deg, #EF6F00 0%, #EE0000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ef6f00;
}

.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 1;
  }
}
.timeline.timeline-6 .timeline-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  margin-bottom: 1.7rem;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.timeline.timeline-6 .timeline-item .timeline-label {
  width: 50px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  color: #3F4254;
}

.timeline.timeline-6 .timeline-item .timeline-badge {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: white;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
  position: relative;
  margin-top: 1px;
  margin-left: -0.5rem;
  padding: 3px !important;
  border: 6px solid #ffffff !important;
}

.timeline.timeline-6 .timeline-item .timeline-content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.timeline.timeline-6:before {
  content: "";
  position: absolute;
  left: 80px;
  width: 3px;
  top: 0;
  bottom: 0;
  background-color: #EBEDF3;
}

.login-body {
  background: url("../../media/bg/bg-3.jpg");
}

.form-control.is-invalid {
  border-color: #EE2D41 !important;
}

.modal-backdrop {
  z-index: 100;
}

.add-scroll {
  width: auto;
}